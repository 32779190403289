<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <YesOrNoRadio
      name="integrador_quimico"
      label="Presença de integrador químico*"
      v-model="integrador_quimico"
      @input="(v) => $emit('updateModel', { integrador_quimico: v })"
      :disabled="isBusy"
      v-validate="{ required: true }"
      :state="validateState('integrador_quimico')"
    />
  </b-col>
</template>

<script>
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';

export default {
  name: 'IntegradorQuimico',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: { YesOrNoRadio },

  data() {
    return {
      integrador_quimico: this.options.defaultValue,
    };
  },

  mounted() {
    this.$emit('updateModel', { integrador_quimico: this.integrador_quimico });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
