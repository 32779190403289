<template>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Nome do Médico"
          label-for="nomeMedico"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do médico'
            "
            class="tooltip-icon"
          />
          <b-form-input
            name="nomeMedico"
            v-model="nome_medico"
            @input="(v) => $emit('updateModel', { nome_medico: v })"
            class="invision-input input-icon"
            :disabled="isBusy"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('nomeMedico')"
          ></b-form-input>
        </b-form-group>
      </b-col>
</template>

<script>
export default {
  name: 'NomeMedico',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      nome_medico: '',
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
