<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px">
    <b-row v-if="options.avisoLimiteEsterilizacao">
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-alert show variant="warning">
          <b-row>
            <AlertCircleIcon class="mr-3" />
            <span>Material atingiu o limite de esterilizações permitido.</span>
          </b-row>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="!options.hideQtdPecas">
      <b-col sm="12" md="12" lg="12" xl="12" v-if="options.quantidadeItens">
        <label
          v-if="
            options.quantidadeAtualItens &&
            options.quantidadeItens &&
            options.quantidadeAtualItens !== options.quantidadeItens
          "
        >
          Esse material foi cadastrado com {{ options.quantidadeItens }} itens,
          mas apenas {{ options.quantidadeAtualItens }} foram informados no
          último processo.
        </label>
        <b-form-group label="Quantidade de peças*" label-for="quantidade_pecas">
          <b-form-input
            style="max-width: 15rem;"
            class="invision-input"
            name="quantidade_pecas"
            type="number"
            min="1"
            v-model="quantidade_pecas"
            :disabled="isBusy"
            v-validate="validacao"
            :state="validateState('quantidade_pecas')"
            @input="(v) => $emit('updateModel', { quantidade_pecas: v })"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        v-if="modificouQuantidade && !options.hideJustificativa"
      >
        <b-form-group label="Justificativa*" label-for="justificativa">
          <b-form-input
            class="invision-input"
            name="justificativa"
            v-model="justificativa"
            @input="(v) => $emit('updateModel', { justificativa: v })"
            :disabled="isBusy"
            v-validate="{
              required: true,
              isHigherThanMaxZplChars: [justificativa],
            }"
            :state="validateState('justificativa')"
          >
          </b-form-input>
          <b-form-invalid-feedback v-if="justificativa.length > 0">
            A quantidade de caracteres da justificativa superou o limite.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'QuantidadePecas',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      quantidade_pecas:
        this.options.quantidadeAtualItens || this.options.quantidadeItens,

      justificativa: '',
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  watch: {
    quantidade_pecas() {
      if (this.modificouQuantidade) {
        this.justificativa = '';
        this.$emit('updateModel', { justificativa: this.justificativa });
      }
    },
  },

  computed: {
    modificouQuantidade() {
      if (
        this.options.quantidadeItens
        && !this.options.quantidadeAtualItens
        && this.options.quantidadeItens !== +this.quantidade_pecas
      ) {
        return true;
      }

      if (
        this.options.quantidadeItens
        && this.options.quantidadeAtualItens
        && this.options.quantidadeItens !== +this.quantidade_pecas
        && this.options.quantidadeAtualItens !== +this.quantidade_pecas
      ) {
        return true;
      }

      return false;
    },

    validacao() {
      if (this.options.permiteQualquerQuantidade) return {};

      return {
        betweenFiftyPorcentLessAndGreater: [this.options.quantidadeItens],
        max_value: [this.options.quantidadeItens],
      };
    },
  },

  mounted() {
    this.$emit('updateModel', { quantidade_pecas: this.quantidade_pecas });
  },
};
</script>
