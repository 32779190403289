<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
      :label="label"
      label-for="responsavel_processamento"
      label-class="float-left"
    >
      <info-icon
        v-if="options.tooltip"
        v-b-tooltip.hover.right="options.tooltip"
        class="tooltip-icon"
      />
      <b-form-input
        name="responsavel_processamento"
        v-model="responsavel_processamento"
        @input="(v) => $emit('updateModel', { responsavel_processamento: v })"
        class="invision-input"
        :disabled="isBusy"
        v-validate="{ required: true, min: 3 }"
        :state="validateState('responsavel_processamento')"
      ></b-form-input>
    </b-form-group>
  </b-col>
</template>

<script>

export default {
  name: 'ResponsavelProcessamento',
  inject: ['validator', 'validateState'],

  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      responsavel_processamento: null,
    };
  },

  computed: {
    label() {
      if (this.options && this.options.label) return this.options.label;
      return 'Responsável pelo processamento';
    },
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
