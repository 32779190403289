<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group label="Tipo de manutencao*" label-for="tipo_manutencao">
      <GenericSelect
        name="tipo_manutencao"
        labelKey="nome"
        route="tipoManutencao"
        ref="tipoManutencao"
        v-validate="{ required: true }"
        disabledFirstOption
        v-model="id_tipo_manutencao"
        :customFilters="options.tipoManutencao.filters"
        @input="updateTipoManutencao($event)"
        :state="validateState('id_tipo_manutencao')"
      />
    </b-form-group>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '../../components/Form/GenericSelect';

export default {
  name: 'TipoManutencao',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_tipo_manutencao: null,
    };
  },

  computed: {
    ...mapState(['currentUnidade']),
  },
  mounted() {
    this.$emit('updateModel', { id_unidade_destino: this.currentUnidade.id_unidade });
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  methods: {
    updateTipoManutencao(v) {
      const tipoManutencao = this.$refs.tipoManutencao.apiResponse.find(
        (el) => el.id_tipo_manutencao === v,
      );
      this.$emit('updateModel', { tipoManutencao: tipoManutencao.nome });
      this.$emit('updateModel', { id_tipo_manutencao: v });
    },
  },
};
</script>
