<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <br/>
    <b-form-checkbox
      v-model="ciclo_urgencia"
      inline
      @input="(v) => $emit('updateModel', { ciclo_urgencia: v })"
    >
      Ciclo de urgência
    </b-form-checkbox>
  </b-col>
</template>

<script>
export default {
  name: 'CicloUrgencia',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      ciclo_urgencia: false,
    };
  },

  mounted() {
    this.$emit('updateModel', { ciclo_urgencia: false });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
