<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group :label="label" label-for="tipo_embalagem">
      <GenericSelect
        name="unidade"
        labelKey="nome"
        route="unidade"
        v-validate="{ required: options.required }"
        disabledFirstOption
        :state="validateState('unidade')"
        v-model="id_unidade"
        :customFilters="options.filters"
        @input="(v) => $emit('updateModel', { id_unidade: v })"
      ></GenericSelect>
    </b-form-group>
  </b-col>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'Unidade',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_unidade: this.options.id_unidade || null,
    };
  },

  computed: {
    label() {
      return this.options.label || 'Unidade';
    },
  },

  mounted() {
    this.$emit('updateModel', { id_unidade: this.id_unidade });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
