<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group :label="label" label-for="data">
      <b-form-datepicker
        class="invision-input"
        placeholder="Data"
        v-model="date"
        name="data"
        :date-format-options="{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }"
        locale="pt"
        v-validate="{ required: options.required }"
        :state="validateState('data')"
        @input="(v) => $emit('updateModel', { date: v })"
      >
      </b-form-datepicker>
    </b-form-group>
  </b-col>
</template>

<script>

export default {
  name: 'Data',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      date: this.options.date || null,
    };
  },

  computed: {
    label() {
      return this.options.label || 'Data';
    },
  },

  mounted() {
    this.$emit('updateModel', { date: this.date });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
