<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col v-show="!options.unidadeDestino.hide" class="input-p" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Unidade de destino*" label-for="unidade_destino" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="'Informe o nome da unidade para onde o material vai'"
            class="tooltip-icon"
          />
          <GenericSelect
            name="unidade_destino"
            labelKey="nome"
            route="unidade"
            v-model="id_unidade_destino"
            disabledFirstOption
            @input="onUnidadeInput"
            :disabled="isBusy || unidadeDisabled"
            :customFilters="options.unidadeDestino.filters"
            v-validate="{ required: true }"
            :state="validateState('unidade_destino')"
          />
        </b-form-group>
      </b-col>

      <b-col v-show="!options.setorDestino.hide" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Setor de destino*" label-for="setor_destino" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do setor para onde o material vai'
            "
            class="tooltip-icon"
          />
          <GenericSelect
            name="setor_destino"
            labelKey="nome"
            route="setor"
            v-model="id_setor_destino"
            disabledFirstOption
            @input="(v) => $emit('updateModel', { id_setor_destino: v })"
            :disabled="isBusy || setorDisabled"
            :customFilters="{ ...options.setorDestino.filters, ...setorSelectFilters }"
            v-validate="{ required: true }"
            :state="validateState('setor_destino')"
          ></GenericSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'UnidadeSetorDestino',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      unidadeDisabled: false,
      id_unidade_destino: (this.options.unidadeDestino && this.options.unidadeDestino.default) || null,

      setorDisabled: false,
      id_setor_destino: (this.options.setorDestino && this.options.setorDestino.default) || null,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  computed: {
    setorSelectFilters() {
      if (this.options.unidadeDestino.hide) return {};

      if (this.id_unidade_destino) return { id_unidade: this.id_unidade_destino };
      return { id_unidade: 0 };
    },
  },

  methods: {
    async onUnidadeInput(v) {
      this.$emit('updateModel', { id_unidade_destino: v });

      await this.$nextTick();
      this.id_setor_destino = (this.options.setorDestino && this.options.setorDestino.default) || this.id_setor_destino;
      this.$emit('updateModel', { id_setor_destino: this.id_setor_destino });
    },
  },

  async mounted() {
    if (this.options.unidadeDestino.disabled) this.unidadeDisabled = true;
    if (this.options.setorDestino.disabled) this.setorDisabled = true;

    if (!this.options.unidadeDestino.hide) this.$emit('updateModel', { id_unidade_destino: this.id_unidade_destino });
    this.$emit('updateModel', { id_setor_destino: this.id_setor_destino });
  },
};
</script>
