<template>
  <div class="text-center">
    <b-row
      v-if='error'
      class='alert alert-error alert-danger fade show mx-0'
      role='danger'
    >
      {{ error }}
    </b-row>

    <b-row style="padding-left: 15px;">
      <b-col cols="9" style="padding: 0px 4px">
        <div v-if="inputType === 'camera'">
          <h2 v-if="!cameraReady">
            ...Ativando!
          </h2>
          <qrcode-stream
            :camera="camera"
            @decode="onCameraDecode"
            @init="onCameraInit"
            style="width: 80%; margin-left: 10%"
          >
            <RepeatIcon
              id="cameraRotateIcon"
              v-if="cameraReady"
              @click="switchCamera"
            />
          </qrcode-stream>
          <br />
        </div>

        <div v-else-if="inputType === 'reader'">
          <b-input-group label-for="reader">
            <b-form-input
              name="reader"
              autofocus
              @input="onReaderInput"
              v-model="reader.value"
              ref="qrCodeReaderInput"
              class="invision-input"
              @focus="$emit('focus')"
              @blur="$emit('blur')"
              v-validate="{ required: false }"
            />
          </b-input-group>
          <br />
        </div>

        <div v-else-if="inputType === 'id'">
          <b-input-group>
            <b-form-input
              name="id"
              v-model="idNaoGenerico.value"
              placeholder="Digite o ID do material"
              @keyup.enter="onIdInput"
              class="invision-input"
              v-validate="{ required: false }"
              @focus="$emit('focus')"
              @blur="$emit('blur')"
            />
            <b-input-group-append>
              <Button
                @click="onIdInput"
                type="outline"
                class="inlineBtn"
                text="Buscar"
                :loading="isBusy"
              />
            </b-input-group-append>
          </b-input-group>
          <br />
        </div>

        <div v-else-if="inputType === 'tipo'" :class="loading.idGenerico && 'opac-30'">
          <form @keyup.enter="onMaterialGenericoSelection" @submit.stop.prevent data-vv-scope="materialDetection">
            <div v-if="loading.idGenerico" class="loading-block">
              <div class="loading-background" />
              <b-spinner class="spinner"></b-spinner>
            </div>

            <b-row class="mb-4">
              <b-col cols="4" style="padding: 0px 2px">
                <b-form-select
                  class="invision-input w-100 input-generico"
                  name="tipo_material_generico"
                  :options="idGenerico.tipoOptions"
                  v-model="idGenerico.tipo"
                  @input="onTipoMaterialGenericoChange"
                  @focus="$emit('focus')"
                  @blur="$emit('blur')"
                >
                  <template slot="first">
                    <option :value="null">
                      -- Selecione uma opção --
                    </option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col cols="4" style="padding: 0px 2px">
                <v-select
                  name="idGenerico"
                  class="invision-select-typing w-100 input-generico"
                  placeholder="-- Selecione uma opção --"
                  :disabled="!idGenerico.tipo"
                  v-model="idGenerico.value"
                  :options="idGenerico.options"
                  @focus="$emit('focus')"
                  @blur="$emit('blur')"
                >
                  <template #no-options>
                    <em style="opacity: 0.5">Nenhuma opção disponível</em>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="4" style="padding: 0px 11px 0px 2px">
                <b-form-input
                  class="w-100 input-generico"
                  type="number"
                  placeholder="Quantidade"
                  min="1"
                  v-model="idGenerico.quantidade"
                  @blur="onMaterialGenericoSelection"
                />
              </b-col>
            </b-row>
          </form>
        </div>
      </b-col>

      <b-col cols="3" style="padding: 0px 4px">
        <b-form-select
          class="invision-input w-100 type-select"
          v-model="inputType"
          :options="inputOptions"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapState, mapActions } from 'vuex';
import Button from '@/components/Utils/Button';

export default {
  name: 'MaterialDetection',
  components: {
    QrcodeStream,
    Button,
  },

  data() {
    return {
      error: '',
      inputType: 'reader',
      loading: {
        idGenerico: false,
      },

      reader: {
        value: '',
        timeout: null,
      },

      idNaoGenerico: {
        value: null,
      },

      idGenerico: {
        value: null,
        tipo: null,
        quantidade: null,

        tipoOptions: [],
        options: [],
      },
      camera: 'auto',
      noRearCamera: false,
      noFrontCamera: false,
      cameraReady: false,
    };
  },

  computed: {
    ...mapState(['materiaisGenericosByTipo']),

    inputOptions() {
      const options = [{ text: 'Leitor', value: 'reader' }];
      if (!this.hideCamera) {
        options.push({ text: 'Câmera', value: 'camera' });
      }
      if (!this.hideId) {
        options.push({ text: 'ID', value: 'id' });
      }
      if (!this.hideMateriaisGenerico) {
        options.push({ text: 'Tipo', value: 'tipo' });
      }
      return options;
    },
  },

  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    hideCamera: {
      type: Boolean,
      default: false,
    },
    hideMateriaisGenerico: {
      type: Boolean,
      default: false,
    },
    hideId: {
      type: Boolean,
      default: false,
    },
    idTipoProcesso: {
      type: Number,
      required: false,
    },
  },

  mounted() {
    this.readerAutoFocus();
  },

  watch: {
    inputType: {
      async handler(val) {
        this.error = '';

        if (val === 'reader') {
          setTimeout(this.readerAutoFocus.bind(this), 200);
        }

        if (val === 'tipo') {
          if (!this.materiaisGenericosByTipo) {
            try {
              this.loading.idGenerico = true;
              this.$emit('isBusy', true);
              await this.getMateriaisGenericosByTipo();
            } finally {
              this.loading.idGenerico = false;
              this.$emit('isBusy', false);
            }
          }

          this.fillTipoMaterialGenericoOptions();
        }
      },
    },

    'idGenerico.tipo': {
      handler(value) {
        this.onTipoMaterialGenericoChange(value);
      },
    },
  },

  methods: {
    switchCamera() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear';
          break;
        case 'rear':
          this.camera = 'front';
          break;
        default:
          this.camera = 'front';
      }
    },

    ...mapActions(['getMateriaisGenericosByTipo']),

    onDetection(value, type, quantidade = 1) {
      this.$emit('input', { value, type, quantidade });
    },

    async onCameraDecode(value) {
      if (this.isBusy || !value) return;
      this.detecting = value;
      this.onDetection(value, 'code');
    },

    async onCameraInit(promise) {
      try {
        this.cameraReady = false;
        await promise;
        this.cameraReady = true;
        this.error = null;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.camera = 'auto';
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      }
    },

    readerAutoFocus() {
      if (this.$refs.qrCodeReaderInput) {
        this.$refs.qrCodeReaderInput.$el.focus();
      }
    },

    onReaderInput(value) {
      if (this.isBusy || !value) return;

      if (this.reader.timeout) clearTimeout(this.reader.timeout);
      this.reader.timeout = setTimeout(async () => {
        await this.onDetection(value, 'code');
        this.reader.value = '';
      }, 500);
    },

    async onIdInput() {
      if (this.isBusy || !this.idNaoGenerico.value) return;

      await this.onDetection(this.idNaoGenerico.value, 'id');
      this.idNaoGenerico.value = '';
    },

    fillTipoMaterialGenericoOptions() {
      this.idGenerico.tipoOptions = this.materiaisGenericosByTipo.reduce((acc, tmg) => {
        if (this.idTipoProcesso
          && !tmg.tipoProcesso.some((tp) => tp.id_tipo_processo === this.idTipoProcesso)) return acc;
        return [
          ...acc,
          { text: tmg.nome, value: tmg.id_tipo_material_generico },
        ];
      }, []);

      if (this.idGenerico.tipoOptions.length === 1) {
        this.idGenerico.tipo = this.idGenerico.tipoOptions[0].value;
      }
    },

    onTipoMaterialGenericoChange(v) {
      this.idGenerico.value = null;

      if (v) {
        const tmg = this.materiaisGenericosByTipo.find((m) => m.id_tipo_material_generico === v);

        this.idGenerico.options = tmg.materialGenerico.map((mg) => ({
          ...mg,
          tipoMaterialGenerico: {
            nome: tmg.nome,
            id_tipo_material_generico: tmg.id_tipo_material_generico,
          },
        }));
      } else {
        this.idGenerico.options = [];
      }
    },

    async onMaterialGenericoSelection() {
      if (!this.idGenerico.tipo || !this.idGenerico.value || !this.idGenerico.quantidade) {
        return;
      }

      if (this.idGenerico.tipoOptions.length > 1) {
        this.idGenerico.tipo = null;
        this.idGenerico.options = [];
      }

      this.onDetection(this.idGenerico.value.id_material_generico, 'generico', this.idGenerico.quantidade);

      this.idGenerico.value = null;
      this.idGenerico.quantidade = null;
    },
  },
};
</script>

<style scoped>
.input-generico {
  height: 2.5rem;
}

.type-select {
  color: #209F85 !important;
}

.loading-block {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
}

.loading-block .spinner {
  color: #209f85;
  width: 8rem;
  height: 8rem;
}

.loading-block .loading-background {
  position: absolute;
  background-color: #dddddd;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
.radio-group {
  width: 100%;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.inlineBtn {
  border: 1px solid #ced4da;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.w-20 {
  width: 20%;
}
.opac-30 {
  opacity: 0.3;
}
.spinner-tipo-generico {
  position: absolute;
  width: 100%;
  height: 100%;
}

#cameraRotateIcon {
  padding: 3px;
  border-radius: 50%;
  float: right;
  margin-top: 6px;
  margin-right: 6px;
  cursor: grab;
  background-color: rgb(255, 255, 255, 0.4);
}
</style>

<style>
.invision-select-typing .vs__dropdown-toggle {
  height: 100%;
  padding: 0;
  border-radius: 7px;
  border: 1px solid #ced4da;
}
.invision-select-typing .vs__selected {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__search {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__dropdown-option {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

</style>
