<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          :label="title || 'Tipo de desinfecção*'"
          label-for="tipo_esterilizacao"
        >
          <GenericSelect
            name="tipo_esterilizacao"
            labelKey="nome"
            route="tipoEsterilizacao"
            :customFilters="options.filters"
            disabledFirstOption
            v-validate="{ required: true }"
            :state="validateState('tipo_esterilizacao')"
            v-model="id_tipo_esterilizacao"
            @input="onTipoEsterilizacaoChange"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Lote da carga*"
          label-for="lote"
          v-if="esterilizacoesUtilizamLote.includes(id_tipo_esterilizacao)"
        >
          <b-form-input
            name="lote"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('lote')"
            v-model="lote"
            @input="(v) => $emit('updateModel', { lote: v })"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'TipoEsterilizacao',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_tipo_esterilizacao: null,
      lote: null,
    };
  },

  mounted() {
    this.$emit('updateModel', { id_tipo_esterilizacao: this.id_tipo_esterilizacao });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },

  computed: {
    esterilizacoesUtilizamLote() {
      return this.options.tiposEsterilizacaoComLote || [];
    },
  },

  methods: {
    onTipoEsterilizacaoChange(value) {
      this.$emit('updateModel', { id_tipo_esterilizacao: value });

      if (!this.esterilizacoesUtilizamLote.includes(value)) this.lote = '';
    },
  },
};
</script>
