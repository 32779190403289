<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group :label="label" label-for="horario">
      <HourInput
        name="input_hour"
        v-validate="{ required: options.required }"
        :state="validateState('input_hour')"
        :isBusy="false"
        v-model="horario"
        @input="(v) => $emit('updateModel', { horario: v })"
      />
    </b-form-group>
  </b-col>
</template>

<script>
import HourInput from '@/components/Utils/Hour';

export default {
  name: 'Horario',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    HourInput,
  },

  data() {
    return {
      horario: this.options.horario || null,
    };
  },

  computed: {
    label() {
      return this.options.label || 'Horário';
    },
  },

  mounted() {
    this.$emit('updateModel', { horario: this.horario });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
