<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
        <YesOrNoRadio
          name="materiais_defeituosos"
          label="Presença de materiais danificados"
          v-model="materiais_defeituosos"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('materiais_defeituosos')"
          @input="(v) => $emit('updateModel', { materiais_defeituosos: v })"
        />
      </b-col>
    </b-row>
    <div class="sub-form" v-if="materiais_defeituosos">
      <b-row>
        <b-col
          v-if="materiais_defeituosos && options.quantidadeItens"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <b-form-group
            label="Quantidade de peças danificadas*"
            label-for="qtd_materiais_defeituosos"
          >
            <b-form-input
              name="qtd_materiais_defeituosos"
              type="number"
              v-model="quantidade_materiais_defeituosos"
              autocomplete="off"
              min="1"
              class="invision-input"
              :disabled="isBusy"
              v-validate="`required|between:1,${options.quantidadeItens}`"
              :state="validateState('qtd_materiais_defeituosos')"
              @input="onQtdDefeituosoChange"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="quantidade_materiais_defeituosos >= 1">
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group
            label="Descrição*"
            label-for="descricao_materiais_defeituosos"
          >
            <GenericSelect
              v-for="(_, index) in ids_defeitos"
              :key="`idDefeito${index}`"
              class="invision-select mt-1"
              :name="`descricao_materiais_defeituosos-${index}`"
              autocomplete="off"
              maxlength="500"
              labelKey="nome"
              :value="ids_defeitos[index]"
              route="tipoDefeitoMaterial"
              :disabled="isBusy"
              v-validate="{ required: true }"
              :state="validateState(`descricao_materiais_defeituosos-${index}`)"
              @input="(v) => onChangeIdsDefeitos(v, index)"
            ></GenericSelect>
            <Button
              v-if="quantidade_materiais_defeituosos >= 1 && ids_defeitos.length < quantidade_materiais_defeituosos"
              text="Adicionar Descrição"
              variant="secondary"
              class="mt-3"
              @click="addDescricaoDefeito"
              :disabled="isBusy"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';
import Button from '@/components/Utils/Button';
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'ItensDefeituosos',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    YesOrNoRadio,
    Button,
    GenericSelect,
  },

  data() {
    return {
      materiais_defeituosos: this.options.notSelectedOption ? null : Boolean(this.options.value),
      quantidade_materiais_defeituosos: 0,
      ids_defeitos: [],

      justificativa: '',
    };
  },

  mounted() {
    this.$emit('updateModel', { materiais_defeituosos: this.materiais_defeituosos });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      /**
      * Chaves usadas para definir a opção do componente YesOrNoRadio,
      * no caso de não estar presentes serão setados os valores padrão
      * @param {boolean} notSelectedOption permite setar ou não uma opção
      * @param {boolean} value define a opção que estará selecionada
      */
      type: Object,
      required: false,
    },
  },

  methods: {
    onQtdDefeituosoChange() {
      if (this.quantidade_materiais_defeituosos < this.ids_defeitos.length) {
        this.ids_defeitos.length = this.quantidade_materiais_defeituosos;
        this.$emit('updateModel', { ids_defeitos: this.ids_defeitos });
      }

      this.$emit('updateModel', { quantidade_materiais_defeituosos: this.quantidade_materiais_defeituosos });
    },

    onChangeIdsDefeitos(value, index) {
      this.ids_defeitos[index] = value;
      this.$emit('updateModel', { ids_defeitos: this.ids_defeitos });
    },

    addDescricaoDefeito() {
      this.ids_defeitos.push(null);
    },
  },
};
</script>
