<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
      label="Lote do Detergente"
      label-for="loteDetergente"
    >
      <b-form-input
        name="loteDetergente"
        v-model="lote_detergente"
        @input="(v) => $emit('updateModel', { lote_detergente: v })"
        class="invision-input input-icon"
        :disabled="isBusy"
        v-validate="{ required: true }"
        :state="validateState('loteDetergente')"
      ></b-form-input>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'LoteDetergente',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      lote_detergente: this.options.ultimoLote,
    };
  },

  mounted() {
    this.$emit('updateModel', { lote_detergente: this.options.ultimoLote });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
