<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
    label="Aviso*"
    label-for="aviso"
    label-class="float-left"
    >
      <b-form-input
        id="aviso"
        name="aviso"
        v-model="aviso"
        class="invision-input input-icon"
        :disabled="isBusy"
        type='number'
        v-validate="options.validate"
        :state="validateState('aviso')"
        @input="(v) => $emit('updateModel', { aviso: v })"
      />
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'Aviso',
  inject: [
    'validator',
    'validateState',
  ],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      aviso: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
