<template>
  <div>
    <b-row>
      <component
        :ref="`form-${field.component}`"
        v-for="(field, index) in fields"
        :key="`field${index}`"
        :is="field.component"
        :isBusy="isBusy"
        :options="field.options"
        :answers="answers"
        :idUnidade="idUnidade"
        :countPhotos="countPhotos"
        @updateModel="onUpdatedModel"
        @confirmaForm="onFormConfirmation"
        @cancelaForm="onFormCancelation"
      />
    </b-row>
  </div>
</template>

<script>
import HospitalParceiro from './HospitalParceiro';
import UnidadeSetorOrigem from './UnidadeSetorOrigem';
import UnidadeSetorDestino from './UnidadeSetorDestino';
import QuantidadePecas from './QuantidadePecas';
import ItensDefeituosos from './ItensDefeituosos';
import Desincrustante from './Desincrustante';
import PerfuroCortante from './PerfuroCortante';
import Integridade from './Integridade';
import IntegradorQuimico from './IntegradorQuimico';
import TipoEmbalagem from './TipoEmbalagem';
import TipoEsterilizacao from './TipoEsterilizacao';
import Validade from './Validade';
import ResponsavelProcessamento from './ResponsavelProcessamento';
import ResponsavelProcessamentoTexto from './ResponsavelProcessamentoTexto';
import ResponsavelSetor from './ResponsavelSetor';
import ColaboradorResponsavel from './ColaboradorResponsavel';
import EquipamentoLimpezaAutomatizada from './EquipamentoLimpezaAutomatizada';
import EquipamentoEsterilizacao from './EquipamentoEsterilizacao';
import LoteDetergente from './LoteDetergente';
import TesteEsterilizacao from './TesteEsterilizacao';
import CicloUrgencia from './CicloUrgencia';
import Composicao from './Composicao';
import ResponsavelRetirada from './ResponsavelRetirada';
import InstrumentadorResponsavel from './InstrumentadorResponsavel';
import Aviso from './Aviso';
import EquipamentoTermodesinfeccao from './EquipamentoTermodesinfeccao';
import LimpezaManual from './LimpezaManual';
import MotivoRetorno from './MotivoRetorno';
import NomeColaborador from './NomeColaborador';
import Observacoes from './Observacoes';
import Rg from './Rg';
import Sala from './Sala';
import TipoProcessamentoPreparo from './TipoProcessamentoPreparo';
import LoteDetergenteEnzimatico from './LoteDetergenteEnzimatico';
import LoteDesinfetante from './LoteDesinfetante';
import Esterilizacao from './Esterilizacao';
import Unidade from './Unidade';
import Horario from './Horario';
import Data from './Data';
import Justificativa from './Justificativa';
import LoteEquipamentoEsterilizacao from './LoteEquipamentoEsterilizacao';
import NomeMedico from './NomeMedico';
import NomeCirculante from './NomeCirculante';
import DuplaChecagem from './DuplaChecagem';
import PhotoView from '../../components/Utils/PhotoView';
import ValidadeVencida from './ValidadeVencida';
import TipoManutencao from './TipoManutencao';
import Paciente from './Paciente';
import Carrinho from './Carrinho';

export default {
  name: 'DynamicForm',
  inject: ['validator', 'validateState'],

  components: {
    Justificativa,
    HospitalParceiro,
    ResponsavelProcessamento,
    ResponsavelProcessamentoTexto,
    UnidadeSetorOrigem,
    UnidadeSetorDestino,
    QuantidadePecas,
    ItensDefeituosos,
    Desincrustante,
    PerfuroCortante,
    Integridade,
    IntegradorQuimico,
    TipoEmbalagem,
    TipoEsterilizacao,
    Validade,
    ResponsavelSetor,
    ColaboradorResponsavel,
    EquipamentoLimpezaAutomatizada,
    EquipamentoEsterilizacao,
    LoteDetergente,
    TesteEsterilizacao,
    CicloUrgencia,
    Composicao,
    ResponsavelRetirada,
    InstrumentadorResponsavel,
    Aviso,
    EquipamentoTermodesinfeccao,
    LimpezaManual,
    MotivoRetorno,
    NomeColaborador,
    Observacoes,
    Rg,
    Sala,
    TipoProcessamentoPreparo,
    LoteDetergenteEnzimatico,
    LoteDesinfetante,
    Esterilizacao,
    Unidade,
    Horario,
    Data,
    LoteEquipamentoEsterilizacao,
    NomeMedico,
    NomeCirculante,
    DuplaChecagem,
    PhotoView,
    ValidadeVencida,
    TipoManutencao,
    Paciente,
    Carrinho,
  },

  data() {
    return {
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    fields: {
      type: Array,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    idUnidade: {
      type: Number,
      required: true,
    },
    countPhotos: {
      type: Number,
      required: false,
    },
  },

  mounted() {
    Object.keys(this.$refs).forEach((componentKey) => {
      if (!componentKey.startsWith('form-')) return;
      const component = this.$refs[componentKey][0];

      Object.keys(this.answers).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!component.hasOwnProperty(key)) return;

        component[key] = this.answers[key];
        this.onUpdatedModel({ [key]: component[key] });
      });
    });
  },

  methods: {
    async onUpdatedModel(updatedValue) {
      this.$emit('updateModel', updatedValue);
    },

    onFormConfirmation() {
      this.$emit('confirma');
    },

    onFormCancelation() {
      this.$emit('cancela');
    },
  },
};
</script>
