<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col v-show="!options.unidadeOrigem.hide" class="input-p" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Unidade de origem*" label-for="unidade_origem" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="'Informe o nome da unidade de onde o material veio'"
            class="tooltip-icon"
          />
          <GenericSelect
            name="unidade_origem"
            labelKey="nome"
            route="unidade"
            v-model="id_unidade_origem"
            disabledFirstOption
            @input="onUnidadeInput"
            :disabled="isBusy || unidadeDisabled"
            :customFilters="options.unidadeOrigem.filters"
            v-validate="{ required: true }"
            :state="validateState('unidade_origem')"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Setor de origem*" label-for="setor_origem" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do setor de onde o material veio'
            "
            class="tooltip-icon"
          />
          <GenericSelect
            name="setor_origem"
            labelKey="nome"
            route="setor"
            v-model="id_setor_origem"
            disabledFirstOption
            @input="(v) => $emit('updateModel', { id_setor_origem: v })"
            :disabled="isBusy || setorDisabled"
            :customFilters="{ ...options.setorOrigem.filters, ...setorSelectFilters }"
            v-validate="{ required: true }"
            :state="validateState('setor_origem')"
          ></GenericSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'UnidadeSetorOrigem',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      unidadeDisabled: false,
      id_unidade_origem: (this.options.unidadeOrigem && this.options.unidadeOrigem.default) || null,

      setorDisabled: false,
      id_setor_origem: (this.options.setorOrigem && this.options.setorOrigem.default) || null,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  computed: {
    setorSelectFilters() {
      if (this.options.unidadeOrigem.hide) return {};

      if (this.id_unidade_origem) return { id_unidade: this.id_unidade_origem };
      return { id_unidade: 0 };
    },
  },

  methods: {
    async onUnidadeInput(v) {
      this.$emit('updateModel', { id_unidade_origem: v });

      await this.$nextTick();
      this.id_setor_origem = (this.options.setorOrigem && this.options.setorOrigem.default) || this.id_setor_origem;
      this.$emit('updateModel', { id_setor_origem: this.id_setor_origem });
    },
  },

  async mounted() {
    if (this.options.unidadeOrigem.disabled) this.unidadeDisabled = true;
    if (this.options.setorOrigem.disabled) this.setorDisabled = true;

    if (!this.options.unidadeOrigem.hide) this.$emit('updateModel', { id_unidade_origem: this.id_unidade_origem });
    this.$emit('updateModel', { id_setor_origem: this.id_setor_origem });
  },
};
</script>
