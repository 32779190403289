<template>
  <div>
    <template-base class="card">
      <span class="header">
        <slot name="header">
          <label class="titulo-card">{{ title }}</label><br/>
          <label class="subtitulo-card">{{ subTitle }}</label>
        </slot>
        <chevron-up-icon
          v-if="$slots.default"
          class="float-right icon-chevron"
          @click="toogle"
          :class="{ rotated: expanded }"
        />
      </span>
      <transition-expand>
        <slot v-if="expanded" />
      </transition-expand>
    </template-base>
  </div>
</template>

<script>
import TemplateBase from '@/templates/Base';
import TransitionExpand from '@/templates/TransitionExpand';

export default {
  name: 'ExpandableCard',

  components: {
    TemplateBase,
    TransitionExpand,
  },

  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      expanded: true,
    };
  },

  methods: {
    toogle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
  .card {
    padding: 30px 30px 10px !important;
  }

  .titulo-card {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  .subtitulo-card {
    font-size: 0.875rem;
    color: #7E829F !important;
  }

  .icon-chevron {
    margin-top: 3px;
    cursor: pointer;
    transition-duration: 0.4s;
    position: absolute;
    right: 1rem;
    top: 0;
  }
  .icon-chevron.rotated {
    transform: rotate(-180deg);
  }
</style>
