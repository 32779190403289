<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col class="input-p" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Número do carrinho" label-for="carrinho" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="'Informe o número do carrinho'"
            class="tooltip-icon"
          />
          <GenericSelect
            name="carrinho"
            labelKey="nome"
            route="transporte/carrinho"
            v-model="id_carrinho"
            disabledFirstOption
            @input="v => $emit('updateModel', { id_carrinho: v })"
            :disabled="isBusy"
            v-validate="{ required: true, min: 1 }"
            :state="validateState('carrinho')"
          />
        </b-form-group>
      </b-col>

      <b-col class="input-p" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Horário da van" label-for="carrinho" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="'Informe o horário da van'"
            class="tooltip-icon"
          />
          <GenericSelect
            name="horarioVan"
            labelKey="nome"
            route="transporte/horarioVan"
            v-model="id_horario_van"
            disabledFirstOption
            @input="v => $emit('updateModel', { id_horario_van: v })"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('horarioVan')"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'Carrinho',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_carrinho: this.options.carrinho.default || null,
      id_horario_van: this.options.horarioVan.default || null,
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

};
</script>
