<template>
  <b-col sm="12" md="12" lg="12" xl="12">
      <b-form-group label="Motivo do retorno*" label-for="motivo_retorno">
      <GenericSelect
          name="motivo_retorno"
          labelKey="motivo"
          route="motivoRetorno"
          ref="motivoRetorno"
          disabledFirstOption
          v-model="id_motivo_retorno"
          @input="updateMotivoRetorno($event)"
          v-validate="{ required: true }"
          :state="validateState('id_motivo_retorno')"
      />
      </b-form-group>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '../../components/Form/GenericSelect';

export default {
  name: 'MotivoRetorno',
  components: {
    GenericSelect,
  },
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      id_motivo_retorno: null,
    };
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
  },
  mounted() {
    this.$emit('updateModel', { id_unidade_destino: this.currentUnidade.id_unidade });
  },
  methods: {
    updateMotivoRetorno(v) {
      const motivoRetorno = this.$refs.motivoRetorno.apiResponse.find(
        (el) => el.id_motivo_retorno === v,
      );
      this.$emit('updateModel', { motivoRetorno: motivoRetorno.motivo });
      this.$emit('updateModel', { id_motivo_retorno: v });
    },
  },
};
</script>
