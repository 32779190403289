<template>
<div>
    <TipoEsterilizacao
      ref="form-TipoEsterilizacao"
      v-if="options.TipoEsterilizacao"
      :isBusy="isBusy"
      :options="options.TipoEsterilizacao"
      :title="options.title || null"
      @updateModel="onUpdatedModel"
    />

    <EquipamentoEsterilizacao
      ref="form-EquipamentoEsterilizacao"
      v-if="options.EquipamentoEsterilizacao && temEquipamento"
      :isBusy="isBusy"
      :options="{
        ...options.EquipamentoEsterilizacao,
        filters: {
          ...options.EquipamentoEsterilizacao.filters,
          id_tipo_esterilizacao,
          id_unidade: idUnidade,
        },
      }"
      :idUnidade="idUnidade"
      :idTipoEsterilizacao="id_tipo_esterilizacao"
      @updateModel="onUpdatedModel"
    />

    <TesteEsterilizacao
      ref="form-TesteEsterilizacao"
      v-if="options.TesteEsterilizacao && temEquipamento"
      :isBusy="isBusy"
      :options="options.TesteEsterilizacao"
      @updateModel="onUpdatedModel"
    />
</div>
</template>

<script>
import TipoEsterilizacao from './TipoEsterilizacao';
import EquipamentoEsterilizacao from './EquipamentoEsterilizacao';
import TesteEsterilizacao from './TesteEsterilizacao';

export default {
  name: 'Integridade',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    TipoEsterilizacao,
    EquipamentoEsterilizacao,
    TesteEsterilizacao,
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    answers: {
      type: Object,
      required: true,
    },
    idUnidade: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      id_tipo_esterilizacao: null,
      id_equipamento_esterilizacao: null,
      lote: null,
      id_tipo_teste: null,
    };
  },

  computed: {
    temEquipamento() {
      return (this.options.esterilizacoesComEquipamento || []).includes(this.id_tipo_esterilizacao);
    },
  },

  mounted() {
    Object.keys(this.$refs).forEach((componentKey) => {
      if (!componentKey.startsWith('form-')) return;
      const component = this.$refs[componentKey];

      Object.keys(this.answers).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!component.hasOwnProperty(key)) return;

        component[key] = this.answers[key];
        this.onUpdatedModel({ [key]: component[key] });
      });
    });
  },

  methods: {
    onUpdatedModel(value) {
      const key = Object.keys(value)[0];

      if (['id_tipo_esterilizacao', 'lote', 'id_equipamento_esterilizacao', 'id_tipo_teste'].includes(key)) {
        this[key] = value[key];
      }

      this.$emit('updateModel', value);
    },
  },
};
</script>
