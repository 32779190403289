<template>
  <div>
    <div class="text-center">
      <b-form-group>
        <b-form-radio-group
          id="btn-radios-1"
          v-model="inputType"
          class="radio-group-class"
          :button-variant="variantButton"
          :size="size"
          :options="inputOptions"
          buttons
        ></b-form-radio-group>
      </b-form-group>

      <b-row
        v-if='error'
        class='alert alert-error alert-danger fade show mx-0'
        role='danger'
      >
        {{ error }}
        <!-- hack: Input invisível para não validar mudança de página -->
        <b-form-input
          name="input"
          v-show="false"
          v-validate="{ required: true }"
        />
      </b-row>
      <div v-else>
        <div v-if="inputType == 'camera'">
          <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
          <br />
        </div>

        <div v-else-if="inputType == 'reader'">
          <b-form-group label-for="qrcode">
            <b-form-input
              readonly
              type="text"
              onfocus="if (this.hasAttribute('readonly')) {
                  this.removeAttribute('readonly');
                  this.blur();
                  this.focus();
                }
              "
              autocomplete="off"
              v-model="currentQrCode"
              name="reader"
              @input="readerInput"
              ref="qr-code-reader-input"
              class="invision-input"
              id="qrcode"
              :disabled="disabled"
              v-validate="{ required: true }"
              :state="validateState('reader')"
            />
          </b-form-group>
          <br />
        </div>

        <div v-else>
          <form @keyup.enter="loginButton" @submit.stop.prevent data-vv-scope="loginForm">
            <b-form-group label="Registro*" label-align="left">
              <b-form-input
                id="registroOrCode"
                name="registroOrCode"
                autofocus
                v-model="registro"
                autocomplete="off"
                required
                v-validate="{ required: true }"
                :state="validateState('registroOrCode')"
                @blur="loginButton"
              />
            </b-form-group>
            <InputSenha
              name="password"
              v-model="password"
              v-validate="{ required: true }"
              :state="validateState('password')"
              @blur="loginButton"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import InputSenha from '@/components/Form/InputSenha';

export default {
  name: 'Login',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  components: {
    QrcodeStream,
    InputSenha,
  },

  data() {
    return {
      error: '',
      registro: '',
      input: '',
      password: '',
      model: {
        permission: 'rw_registro_entrada',
      },
      autoInputFocus: null,
      readerInputTimeout: null,
      currentQrCode: '',
      currentId: null,
      inputType: 'reader',
      inputOptions: [
        { text: 'Leitor', value: 'reader' },
        { text: 'Registro', value: 'registro' },
      ],
    };
  },

  props: {
    initialReadingState: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    variantButton: {
      type: String,
      default: 'primary-group',
    },
    size: {
      type: String,
      default: '',
    },
    labelForAuth: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.autoFocus();
  },

  watch: {
    inputType: {
      immediate: true,
      handler(val) {
        this.error = '';
        if (val === 'reader') {
          setTimeout(this.autoFocus, 200);
        }
      },
    },

    registro() {
      this.model.registro = this.registro;
      this.$emit('updateModel', this.model);
    },

    password() {
      this.model.password = this.password;
      this.$emit('updateModel', this.model);
    },
  },

  methods: {
    clearFields() {
      this.password = '';
      this.registro = '';
      this.currentQrCode = null;
      this.model.code = null;
    },

    onDecode(value) {
      if (!value) {
        this.model.code = this.currentQrCode.replace(/(\r\n|\n|\r)/gm, '');
      } else {
        this.model.code = value;
      }

      this.$emit('updateModel', this.model);
      this.$emit('onDetection');

      clearTimeout(this.readerInputTimeout);
      this.currentQrCode = null;
      this.model.code = null;
    },

    loginButton() {
      if (!this.registro || !this.password) return;

      this.$emit('updateModel', this.model);
      this.$emit('onDetection');
    },

    autoFocus() {
      if (this.$refs['qr-code-reader-input']) {
        this.$refs['qr-code-reader-input'].$el.focus();
      }
    },

    readerInput() {
      clearTimeout(this.readerInputTimeout);
      this.readerInputTimeout = setInterval(this.onDecode.bind(this), 500);
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      }
    },
  },
};
</script>

<style scoped>
.label {
  position: relative;
  right: 330px;
}
.label-for-auth {
  position: relative;
  right: 265px;
}

.radio-group-class {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.loading-block {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
}

.loading-block .spinner {
  color: #209f85;
  width: 10rem;
  height: 10rem;
}

.loading-block .loading-background {
  position: absolute;
  background-color: #dddddd;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
</style>
