<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group label="Observações" label-for="observacoes">
      <b-form-input
        name="observacoes"
        v-model="observacoes"
        autocomplete="off"
        class="invision-input"
        v-validate="{ required: false, isHigherThanMaxZplChars:[observacoes] }"
        :state="validateState('observacoes')"
        :disabled="isBusy"
        @input="v => $emit('updateModel',{ observacoes: v })"
      />
      <b-form-invalid-feedback>
        A quantidade de caracteres da observação
        superou o limite.
      </b-form-invalid-feedback>
    </b-form-group>
  </b-col>
</template>

<script>

export default {
  name: 'Observacoes',
  components: {
  },
  inject: [
    'validator',
    'validateState',
  ],

  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      observacoes: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    justificativa: {
      type: String,
      required: false,
    },
  },

};
</script>
