<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-checkbox
      :checked="nao_fazer_automatizada"
      @input="v => $emit('updateModel', { nao_fazer_automatizada: v })"
      :state="validateState('nao_fazer_automatizada')"
      v-model="nao_fazer_automatizada"
      v-validate="'hasToBeTrue'"
      name="nao_fazer_automatizada"
    >
      Desejo finalizar o processo sem registro de limpeza automatizada ou termodesinfecção
    </b-form-checkbox>
  </b-col>
</template>

<script>
export default {
  name: 'LimpezaManual',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      nao_fazer_automatizada: false,
    };
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
