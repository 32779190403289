<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
      <b-row class="mb-2">
        <b-col>
          <div>
            Solicite um documento de identificação com foto do responsável pela
            retirada de materiais e preencha os campos a seguir.
          </div>
        </b-col>
      </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group
                label="RG*"
                label-for="rg"
                label-class="float-left"
            >
              <b-form-input
                id="rg"
                name="rg"
                class="invision-input"
                v-model="rg"
                autocomplete="off"
                v-validate="{ required: true, min: 5 }"
                :state="validateState('rg')"
                @input="(v) => $emit('updateModel', { rg: v })"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
  </div>
</template>

<script>

export default {
  name: 'Rg',
  inject: [
    'validator',
    'validateState',
  ],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      rg: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
