<template>
  <b-form-group
    :label="`${label}*`"
    label-for="input_colaborador"
  >
    <b-spinner v-if="isBusy || isBusyLocal" small variant="info" class="mini-spinner" />
    <div v-else>
      <b-form-checkbox
        v-model="boxChecked"
        @change="copyfromAuthentication"
        size="sm"
        v-if="usuarioLogadoProcesso.id_usuario"
        :disabled="isBusy || isBusyLocal"
      >
        Copiar autenticação
      </b-form-checkbox>
      <b-form-input
        name="input_colaborador"
        v-model="colaborador"
        autocomplete="off"
        class="invision-input"
        placeholder="Leia o QrCode"
        :readonly="colaboradorReadOnly"
        @input="handleChangeColaborador"
        v-validate="{ required: true }"
        :state="validateState('input_colaborador')"
      ></b-form-input>
    </div>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from '@/helpers/common';
import genericRequest from '@/services/genericRequest';

export default {
  name: 'Colaborador',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Colaborador',
    },
    id_usuario: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapState(['usuarioLogadoProcesso']),
  },

  data() {
    return {
      colaborador: '',

      colaboradorReadOnly: false,
      isBusyLocal: false,
      boxChecked: false,
    };
  },

  mounted() {
    setTimeout(this.handleRespostaAnterior.bind(this), 20);
  },

  methods: {
    async handleRespostaAnterior() {
      if (this.id_usuario && this.id_usuario === this.usuarioLogadoProcesso.id_usuario) {
        this.boxChecked = true;
        this.colaborador = this.usuarioLogadoProcesso.nome;
        this.colaboradorReadOnly = true;
      } else if (this.id_usuario) {
        try {
          this.isBusyLocal = true;

          const colaborador = await genericRequest.get({}, `usuario/${this.id_usuario}`);
          this.colaborador = colaborador.nome;
          this.colaboradorReadOnly = true;
        } catch (error) {
          let errorMessage;
          if (
            error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
          ) {
            errorMessage = error.response.data.error.errorMessage;
          } else {
            errorMessage = 'Ocorreu um problema ao recuperar o colaborador selecionado anteriormente';
          }

          swal({
            icon: 'error',
            title: 'Erro',
            text: errorMessage,
            buttons: { confirm: 'Fechar' },
          });

          this.colaborador = '';
          this.colaboradorReadOnly = false;
        } finally {
          this.isBusyLocal = false;
        }
      }
    },

    handleChangeColaborador: debounce(function debouncing(code) {
      this.handleDetectColaborador(code);
    }, 500),

    async handleDetectColaborador(code) {
      try {
        this.isBusyLocal = true;

        const colaborador = await genericRequest.get({}, `usuario/byCode/${code}`);

        if (!colaborador.id_usuario) {
          throw 'Usuáro não cadastrado';
        }

        this.$emit('input', colaborador.id_usuario);

        this.colaborador = colaborador.nome;
        this.colaboradorReadOnly = true;
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        } else {
          errorMessage = 'Ocorreu um problema ao recuperar o colaborador';
        }

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });

        this.colaborador = '';
        this.colaboradorReadOnly = false;
      } finally {
        this.isBusyLocal = false;
      }
    },

    copyfromAuthentication(checked) {
      if (checked) {
        this.$emit('input', this.usuarioLogadoProcesso.id_usuario);
        this.colaborador = this.usuarioLogadoProcesso.nome;
        this.colaboradorReadOnly = true;
      } else {
        this.$emit('input', null);
        this.colaborador = '';
        this.colaboradorReadOnly = false;
      }
    },
  },
};
</script>
