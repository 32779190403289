<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Tipo de processamento">
          <b-form-radio-group
            v-model="tipo_lavagem"
            @input="onTipoLavagemChange"
            v-validate="{ required: true }"
            :state="validateState('tipo_lavagem')"
            name="tipo_lavagem"
          >
            <b-form-radio
              v-for="(option) in options.tiposLavagem"
              :key="`tipoLavagem${option.value}`"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <div v-if="tipo_lavagem === 'automatizada'">
        <EquipamentoLimpezaAutomatizada
          ref="form-EquipamentoLimpezaAutomatizada"
          :isBusy="isBusy"
          :options="options.equipamentoLimpezaAutomatizada"
          :idUnidade="idUnidade"
          @updateModel="onUpdatedModel"
        />
      </div>
      <div v-if="tipo_lavagem === 'termodesinfeccao'">
        <EquipamentoTermodesinfeccao
          ref="form-EquipamentoTermodesinfeccao"
          :isBusy="isBusy"
          :options="options.equipamentoTermodesinfeccao"
          :idUnidade="idUnidade"
          @updateModel="onUpdatedModel"
        />
      </div>
      <div v-if="tipo_lavagem === 'manual'">
        <LimpezaManual
          v-if="!options.semLimpezaManual"
          ref="form-LimpezaManual"
          :isBusy="isBusy"
          @updateModel="onUpdatedModel"
        />
        <SecagemEnxague
          v-if="options.enxagueESecagem"
          ref="form-SecagemEnxague"
          :isBusy="isBusy"
          @updateModel="onUpdatedModel"
        />
      </div>
      <div v-if="tipoLavagemComResponsavel.includes(tipo_lavagem)">
        <ResponsavelProcessamento
          v-if="!options.responsavelProcessamento.textoLivre"
          ref="form-ResponsavelProcessamento"
          :isBusy="isBusy"
          :options="options.responsavelProcessamento"
          @updateModel="onUpdatedModel"
        />
        <ResponsavelProcessamentoTexto
          v-else
          ref="form-ResponsavelProcessamentoTexto"
          :isBusy="isBusy"
          :options="options.responsavelProcessamento"
          @updateModel="onUpdatedModel"
        />
      </div>
    </b-row>
  </div>
</template>

<script>
import EquipamentoLimpezaAutomatizada from './EquipamentoLimpezaAutomatizada';
import EquipamentoTermodesinfeccao from './EquipamentoTermodesinfeccao';
import LimpezaManual from './LimpezaManual';
import SecagemEnxague from './SecagemEnxague';
import ResponsavelProcessamento from './ResponsavelProcessamento';
import ResponsavelProcessamentoTexto from './ResponsavelProcessamentoTexto';

export default {
  name: 'TipoProcessamentoPreparo',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    EquipamentoLimpezaAutomatizada,
    EquipamentoTermodesinfeccao,
    LimpezaManual,
    SecagemEnxague,
    ResponsavelProcessamento,
    ResponsavelProcessamentoTexto,
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    answers: {
      type: Object,
      required: true,
    },
    idUnidade: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      id_equipamento_lavagem_automatizada: null,
      id_equipamento_termodesinfeccao: null,
      id_responsavel_processamento: null,
      lote: '',
      nao_fazer_automatizada: false,
      tipo_lavagem: null,
      enxague_e_secagem: false,
    };
  },

  computed: {
    tipoLavagemComResponsavel() {
      if (!this.options.responsavelProcessamento) return [];

      return this.options.responsavelProcessamento.tiposProcesso || [];
    },
  },

  mounted() {
    Object.keys(this.$refs).forEach((componentKey) => {
      if (!componentKey.startsWith('form-')) return;
      const component = this.$refs[componentKey];

      Object.keys(this.answers).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!component.hasOwnProperty(key)) return;

        component[key] = this.answers[key];
        this.onUpdatedModel({ [key]: component[key] });
      });
    });
  },

  methods: {
    onTipoLavagemChange(value) {
      this.$emit('updateModel', { tipo_lavagem: value });

      this.lote = '';
      this.id_equipamento_lavagem_automatizada = null;
      this.id_equipamento_termodesinfeccao = null;
      this.nao_fazer_automatizada = false;
    },

    onUpdatedModel(value) {
      const key = Object.keys(value)[0];

      if (['lote', 'id_equipamento_lavagem_automatizada',
        'id_equipamento_termodesinfeccao', 'nao_fazer_automatizada'].includes(key)) {
        this[key] = value[key];
      }

      this.$emit('updateModel', value);
    },
  },
};
</script>
